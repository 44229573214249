body{
    background:url("../Images/bg.png") no-repeat scroll 0 0 / cover;
}
.camera_row{
    margin: 50px 0;
    padding:20px;
}
.camera_row button{
    margin-top:20px;
}

.Image_Col img{
    min-height: 380px;
    object-fit: cover;
}

.cap_btn svg,.save_btn svg{
    margin-right: 10px;
}
.cap_btn,.save_btn {
    display: flex;
    align-items: center;
    margin: 10px auto 0 auto;
    justify-content: space-between;
}
.logo h2{
    color:#FFF;
    text-align: center;
    padding-top: 20px;
}
@media all and (max-width: 767px){
    .Image_Col img{
        min-height: auto;
    }
    .Camera_Col{
        margin-bottom: 30px;
    }
}